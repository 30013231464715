<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="userdepsid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="节点状态" prop="process_node" style="width: 25%">
              <a-select v-model="queryParams.process_node">
                <a-select-option value="">所有</a-select-option>
                <a-select-option value="10">待处理</a-select-option>
                <a-select-option value="20">待调度员派单</a-select-option>
                <a-select-option value="30">待签到</a-select-option>
                <a-select-option value="40">待执行</a-select-option>
                <a-select-option value="50">申请延期中</a-select-option>
                <a-select-option value="60">申请强关中</a-select-option>
                <a-select-option value="70">待结单</a-select-option>
                <a-select-option value="80">待验收</a-select-option>
                <a-select-option value="91">已完成</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="报修来源" prop="order_source" style="width: 25%">
                <a-select v-model="queryParams.order_source">
                  <a-select-option value="自报">自报</a-select-option>
                  <a-select-option value="调度中心">调度中心</a-select-option>
                  <a-select-option value="外部报">外部报</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="报修单号" prop="order_num" style="width: 25%">
                <a-input v-model="queryParams.order_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="报修类型" prop="repair_matter_type" style="width: 25%">
                <a-select v-model="queryParams.repair_matter_type">
                  <a-select-option value="公区维修">公区维修</a-select-option>
                  <a-select-option value="专属维修">专属维修</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="重大影响" prop="biginfluence" style="width: 25%">
                <a-select v-model="queryParams.biginfluence">
                  <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="工单类型" prop="order_status" style="width: 25%">
                <a-select v-model="queryParams.order_status">
                  <a-select-option value="1">正常</a-select-option>
                  <a-select-option value="2">异常</a-select-option>
                  <a-select-option value="3">拆分</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="计划完成时段" prop="planInstallDate" style="width: 25%">
                <a-range-picker v-model="planInstallDate"></a-range-picker>
              </a-form-model-item>
              <a-form-model-item label="联系人" prop="contacts" style="width: 25%">
                <a-input v-model="queryParams.contacts"></a-input>
              </a-form-model-item>
              <a-form-model-item label="联系人电话" prop="contact_information" style="width: 25%">
                <a-input v-model="queryParams.contact_information"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <!--<a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-button @click="showModal('add')" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="order_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="need_finish_time" slot-scope="value,record">
            <a-tooltip :title="record.changed_time?moment(record.changed_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD'):(value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : '')">
              <span>{{record.changed_time?moment(record.changed_time, 'YYYYMMDDHHmmss').format('YYYY-MM-DD'):(value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : '')}}</span>
            </a-tooltip>
          </span>
          <span slot="process_node" slot-scope="value">
            <a-tag :color="repairOrderStepColor[value]||''">{{repairOrderSteps[value]||''}}</a-tag>
          </span>
          <span slot="order_type" slot-scope="value">
            <span :title="value==0?'报事':'报修'">{{value==0?'报事':'报修'}}</span>
          </span>
          <span slot="order_status" slot-scope="value">
            <span :title="value==3?'拆分':value==2?'异常':'正常'">{{value==3?'拆分':value==2?'异常':'正常'}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.order_id">详情</a-menu-item>
                <a-menu-item :key="'edit-'+record.order_id" v-if="record.process_node==='10'||record.process_node==='20'">修改</a-menu-item>
                <a-menu-item :key="'delete-'+record.order_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
    <detail-modal :visible.sync="detailModalVisible" :detail-data="detailModalDetailData" @get-operation-result="getTableData"></detail-modal>
  </page-container>
</template>

<script>
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
import pagination from "@/mixins/pagination";
import moment from "moment";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import {deleteRepairsOrder, getRepairsOrderListByCondition} from "A/repairsreport";
import addOrEditModal from "V/matterAndRepairs/planRepairs/addOrEditModal";
import DetailModal from "V/matterAndRepairs/planRepairs/DetailModal";
import {repairOrderStepColor, repairOrderStepList, repairOrderSteps} from "@/json/repairsOrderSteps";
export default {
  name: "repairRecord",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
    DetailModal
  },
  data(){
    return{
      moment,
      tableData: [],
      bigInfluences:[],
      tableLoading: false,
      showAdvanced: false,
      repairOrderStepList,
      repairOrderSteps,
      repairOrderStepColor,
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      planInstallDate:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      detailModalVisible:false,
      detailModalDetailData:null,
      queryParams: {
        monitorpointnum: '',
        monitorpointname: '',
        order_type: '1',
        is_plan_repair: '1',
        process_node: '',
        starttime: '',
        endtime: '',
        planstarttime: '',
        planendtime: '',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '计划维修单号',
          dataIndex: 'order_num',
          key: 'order_num',
          ellipsis: true,
        },
        {
          title: '计划来源',
          dataIndex: 'order_source',
          key: 'order_source',
          ellipsis: true,
        },
        {
          title: '维修类型',
          dataIndex: 'repair_matter_type',
          key: 'repair_matter_type',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'device_num',
          key: 'device_num',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'device_name',
          key: 'device_name',
          ellipsis: true,
        },
        {
          title: '维修内容',
          dataIndex: 'handle_content',
          key: 'handle_content',
          ellipsis: true,
        },
        {
          title: '维修形式',
          dataIndex: 'handle_form',
          key: 'handle_form',
          ellipsis: true,
        },
        {
          title: '维修单位',
          dataIndex: 'handle_unit',
          key: 'handle_unit',
          ellipsis: true,
        },
        {
          title: '计划结束日期',
          dataIndex: 'need_finish_time',
          key: 'need_finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'need_finish_time' },
        },
        {
          title: '节点状态',
          dataIndex: 'process_node',
          key: 'process_node',
          ellipsis: true,
          scopedSlots: { customRender: 'process_node' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "计划性维修记录"
    },
    breadcrumb() {
      const pages = [{name:"报事报修",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    planInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.planstarttime = start;
      this.queryParams.planendtime = end;
    },
  },
  created() {
    this.getTableData()
    this.initDeptOptionsAll();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
    }
  },
  methods: {
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getRepairsOrderListByCondition(params).then(res=>{
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'order_id', order_id);
      if(type == 'delete') {
        this.deleteConfirm(order_id, record)
      }else if(type == 'evaluate'){
        this.formData=record
        this.evaluationModalVisible=true
      }else{
        this.showModal(type,record)
      }
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
    },
    showModal(type,record) {
      this.modalDetailData = record
      this.modalShowType = type
      if(type==='detail'){
        this.detailModalDetailData = record
        this.detailModalVisible = true
      }else{
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalShowType='detail'
            this.detailModalDetailData=record
            this.detailModalVisible=true
          },
        },
      }
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.order_id);
      }).catch(()=>{

      }) ;
    },
    delete(order_id) {
      this.showLoading();
      if(order_id) {
        let params = {
          order_id
        };
        deleteRepairsOrder(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
  },
}
</script>

<style scoped>

</style>